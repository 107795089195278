import React from "react";
// CSS helpers
import styled from "styled-components";

// Components

// Atoms
import { Title } from "components/atoms/title";
import { Button } from "components/atoms/button";

// Images
import CommunityTest from "assets/images/test/community-test.jpeg";

import { useState } from "react";

interface HorizontalRowProps {
  mt?: number;
}

const Container = styled.div``;

const HorizontalRow = styled.hr<HorizontalRowProps>`
  margin-right: 80px;
  margin-left: 80px;
  background: ${({ theme }) => theme.colors.gray["400"]};
  height: 1px;
  border-width: 0;
  margin-top: ${({ mt }) => mt ?? 0}px;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-right: 20px;
    margin-left: 20px;
  }

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    margin-right: 40px;
    margin-left: 40px;
  }
`;

const ContentContainer = styled.div`
  display: flex;

  height: 50vh;
  margin-right: 80px;
  margin-top: 80px;
  margin-left: 80px;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;

    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    margin-right: 40px;
    margin-left: 40px;
  }
`;

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray["100"]};

  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    height: 250px;
  }
`;

const Image = styled.img`
  object-fit: cover;
`;

const TextContent = styled.div`
  margin-left: 200px;
  width: 100%;
  line-height: 200%;
  display: block;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const CtaContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 200%;
  color: var(--text-color);
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IframeProps {
  isExperience?: Boolean;
}

const Iframe = styled.iframe<IframeProps>`
  opacity: ${({ isExperience }) => (isExperience === true ? 1 : 0)};
  transition: opacity 500ms ease-in-out;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const HubContainer = () => {
  const [isExperience, setExperience] = useState<boolean | undefined>(false);
  const [accessRequested, setAccessRequested] = useState<boolean | undefined>(
    false
  );

  const accessExperience = async () => {
    setAccessRequested(true);

    const launchExperience = () => {
      console.log("launch exp");

      setExperience(true);
    };

    setTimeout(launchExperience, 2000);
  };

  return (
    <Container>
      <Title
        type="h5"
        mt={100}
        align="center"
        fontSize="48"
        mobilefontsize="30"
      >
        ENTER THE TEST
      </Title>

      <HorizontalRow mt={20} />
      <ContentContainer>
        <ImageContainer>
          <Image src={CommunityTest} alt="Societhy Hub Image" />
        </ImageContainer>

        <TextContent>
          Thank you for being here.
          <br />
          <br />
          This will be a short event to test the multiplayer capabilities of our
          server, which is the last important step before we can confidently
          launch the Opening Ceremony !
          <br />
          <br />
          Join us now for this historic moment.
        </TextContent>
      </ContentContainer>
      <HorizontalRow mt={80} />

      {!accessRequested && (
        <Footer>
          <CtaContainer>
            <Button type="secondary" onClick={accessExperience}>
              ACCESS EXPERIENCE
            </Button>
          </CtaContainer>
        </Footer>
      )}

      {accessRequested && (
        <Footer>
          <CtaContainer>
            The Opening Ceremony Test is launching soon (waiting time ~3min)
          </CtaContainer>
        </Footer>
      )}
      {accessRequested && (
        <Iframe
          src="https://launch.make.live/ilyass/societhy-test?embed=true"
          title="The Openning Ceremony"
          frameBorder={0}
          allowFullScreen
          isExperience={isExperience}
        ></Iframe>
      )}
    </Container>
  );
};
